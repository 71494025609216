.popUp{
    position: absolute;
    inset: 0;
    margin: auto;
    width: 95%;
    max-width: 370px;
    height: 100%;
    max-height: 385px;
    background-color: white;
    box-shadow: 0px 0px 0px 2px #cacaca;
    padding: 3rem 1.5rem;
    z-index: 999;
    border-radius: .65rem;

    &::after{
        content: "";
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.377);
        z-index: -1;
    }
}

h4{
    font-size: 1.7rem !important;
    text-align: center;
}
p{
    margin-top: .35rem !important;
}
.icon{
    margin-top: 1.35rem;
    font-size: 4.5rem;
    &.success{
        padding: 1rem;
        border-radius: 50%;
        border: 4px solid #22bb33;
        color: #22bb33;
    }

    &.error{
        color: #bb2124;
        width: auto;
    }
    &.warning{
        color: 	#f0ad4e;
    }
}

.close{
    margin-top: 1.5rem;
    border: 1px solid;
    padding: .6rem .8rem !important;
    opacity: .9!important;
    border-radius: .35rem;
    transition: all .3s;

    &.success{
        background-color: #22bb33;
    }

    &.error{
        background-color: #bb2124;
        color: #fff;
    }

    &.warning{
        background-color: 	#f0ad4e;
    } 
}